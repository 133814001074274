<template>
  <router-view />
  <!-- 导航方块 -->
  <div class="norem-navigation">
    <div class="header" @click="router.push('/')">
      <img src="@/assets/home/logo.jpg" alt="" />
    </div>
    <div class="bottom">
      <div class="image" @click="router.push('/')">
        <img src="@/assets/home/home.jpg" alt="" />
      </div>
      <div class="image" @click="router.push('/account')">
        <img src="@/assets/home/account.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "G-FE7FLYXZL7");

</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  .norem-navigation {
    padding: 6px;
    width: 90px;
    height: 90px;
    position: fixed;
    top: 200px;
    left: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
    background: #fff;
    opacity: 0.98;
    .header {
      margin-bottom: 2px;
      width: 100%;
      height: 50%;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .bottom {
      display: flex;
      .image {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          border: 1px solid #ccc;
          border-radius: 4px;
          object-fit: cover;
          cursor: pointer;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      div:nth-child(1) {
        margin-right: 2px;
      }
      div:nth-child(2) {
        margin-left: 2px;
      }
    }
  }
}
</style>
